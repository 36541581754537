<!-- User Profile Widget -->
<template>
  <div class="user-profile-widget top-author-wrap">
    <div class="avatar-wrap mb-3 pos-relative">
      <span class="overlay-content">
        <v-img
          v-if="profile.banner16by9"
          :src="profile.banner16by9"
          alt="Banner"
          height="130px"
          gradient="to top, rgba(0,0,0,.8), rgba(0,0,0,.2)"
        />
      </span>
      <div class="user-info">
        <v-img
          :src="profile.avatar"
          alt="reviwers"
          width="100"
          height="100"
          class="img-responsive rounded-circle mr-3"
        />
        <div class="pt-4">
          <h5 class="mb-0 white--text text--darken-2">{{ profile.name }}</h5>
          <span class="fs-14 white--text text--darken-2">{{
            profile.title
          }}</span>
        </div>
      </div>
    </div>
    <div class="author-detail-wrap">
      <div class="pa-3 caption authors-info">
        {{ profile.description | truncate(100) }}
        <ul class="list-unstyled author-contact-info mb-2">
          <li>
            <span class="mr-3"><v-icon>phone</v-icon></span>
            <span v-if="profile.businessPhone">
              <a
                :href="'tel:' + profile.businessPhone"
                class="fs-16 grey--text"
                >{{ profile.businessPhone }}</a
              ></span
            >
            <span v-else> Private </span>
          </li>
          <li>
            <span class="mr-3"><v-icon>mail</v-icon></span>
            <span v-if="profile.businessEmail">
              <a
                :href="'mailto:' + profile.businessEmail"
                class="fs-16 grey--text"
                >{{ profile.businessEmail }}</a
              >
            </span>
            <span v-else> Private </span>
          </li>
          <li v-if="profile.website">
            <span class="mr-3"><v-icon>public</v-icon></span>
            <span>
              <a :href="profile.website" class="fs-16 grey--text">{{
                profile.website
              }}</a>
            </span>
          </li>
          <li v-if="profileDefaultAddress && profileDefaultAddress.city">
            <span class="mr-3"><v-icon>map</v-icon></span>
            <span
              >{{ profileDefaultAddress.city }}
              {{ profileDefaultAddress.state }}</span
            >
          </li>
        </ul>
        <ul class="d-custom-flex social-info list-unstyled">
          <li v-if="profile.facebook">
            <a class="facebook" :href="profile.facebook" target="_blank">
              <v-icon>fab fa-facebook-square</v-icon>
            </a>
          </li>
          <li v-if="profile.twitter">
            <a class="twitter" :href="profile.twitter" target="_blank"
              ><v-icon>fab fa-twitter-square</v-icon></a
            >
          </li>
          <li v-if="profile.linkedin">
            <a class="linkedin" :href="profile.linkedin" target="_blank"
              ><v-icon>fab fa-linkedin</v-icon></a
            >
          </li>
          <li v-if="profile.pinterest">
            <a class="pinterest" :href="profile.pinterest" target="_blank"
              ><v-icon color="red">fab fa-pinterest</v-icon></a
            >
          </li>
          <li v-if="profile.yelp">
            <a class="yelp" :href="profile.yelp" target="_blank"
              ><v-icon>fab fa-yelp</v-icon></a
            >
          </li>
          <li v-if="profile.instagram">
            <a :href="profile.instagram" target="_blank"
              ><v-icon color="red">fab fa-instagram</v-icon></a
            >
          </li>
        </ul>
      </div>
      <div class="d-custom-flex align-center px-3 pb-3">
        <v-btn
          link
          small
          color="primary"
          class="ma-0 mr-3"
          :href="`${viewLink}/producer/${profile.handle}`"
          target="_blank"
        >
          View Profile
        </v-btn>
        <v-btn
          small
          color="warning"
          class="ma-0"
          @click="toMessages(profile.id)"
          >Send Message</v-btn
        >
      </div>
      <ul
        class="d-custom-flex list-unstyled footer-content text-center w-100 border-top-1 align-end justify-center"
      >
        <li>
          <h5 class="mb-0">{{ this.dishes }}</h5>
          <span class="fs-12 grey--text fw-normal">Dishes</span>
        </li>
        <li>
          <h5 class="mb-0">{{ this.followers }}</h5>
          <span class="fs-12 grey--text fw-normal">Followers</span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    profile: {
      Type: Object
    }
  },
  data() {
    return {
      dishes: 0,
      followers: 0
    }
  },
  computed: {
    viewLink() {
      return `https://${process.env.VUE_APP_DOMAIN}`
    }
  },
  mounted() {
    if (this.profile.meta && this.profile.meta.stats) {
      this.dishes = this.profile.meta.stats.dishes || 0
      this.followers = this.profile.meta.stats.followers || 0
    }
  },
  methods: {
    toMessages(recipient) {
      this.$store
        .dispatch('getThread', { recipient: recipient })
        .then(thread_id => {
          this.$router.push('/messages/' + thread_id)
        })
    }
  }
}
</script>
