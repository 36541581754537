<template>
  <v-container>
    <v-row v-if="!loading">
      <v-col cols="12" sm="6">
        <h2>{{ $t('Common.invite_accepted_title') }}</h2>
      </v-col>
      <v-col cols="12" sm="1">
        <v-menu bottom origin="center center">
          <v-btn icon slot="activator"> <v-icon>more_vert</v-icon> </v-btn>
          <v-list>
            <v-list-item to="/invite/list">
              <v-list-item-title>
                View Invitations

                <v-icon color="primary">group</v-icon>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <v-row v-if="profile">
      <v-col cols="12" sm="6"> <profile-view :profile="profile" /> </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import ProfileView from '@/components/chef/profile/ProfileView'
export default {
  data() {
    return {
      profile: null
    }
  },
  components: {
    ProfileView
  },
  mounted() {
    this.$store
      .dispatch('acceptInvite', this.$route.query.invitation_id)
      .then(sender => {
        this.profile = sender
      })
  },
  computed: {
    ...mapGetters({
      user: 'user',
      loading: 'loading'
    })
  },
  methods: {}
}
</script>
